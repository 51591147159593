<template>
	<Fragment>
		<div class="container">
			<div class="body-contents">
				<h2 class="heading-1-b">주문/구매내역</h2>
				<p class="description">*리셀러(파트너사)를 통해 주문/구매를 한 경우 내역을 확인할 수 없습니다.</p>
				<div class="section-wrap">
					<div class="section">
						<table class="basic-table">
							<thead>
								<tr>
									<th class="">
										<span class="txt">서비스 유형</span>
									</th>
									<th class="">
										<span class="txt">기본 서비스명</span>
									</th>
									<th class="">
										<span class="txt">계약(주문) 종류</span>
									</th>
									<th class="date">
										<span class="txt">계약일</span>
									</th>
									<th class="date">
										<span class="txt">적용시작일</span>
									</th>
									<th class="item flex">
										<span class="txt">주요 상품정보 </span>
									</th>
									<th class="charge">
										<span class="txt">계약금(VAT포함)</span>
									</th>
									<th class="method">
										<span class="txt">결제방법</span>
									</th>
									<th class="state">
										<span class="txt">주문상태</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(data, idx) in orderHistories"
									:key="idx"
									v-on:click="data.reseller ? () => {} : goDetailPage(idx)"
									:style="data.reseller ? 'pointer-events : none' : ''"
								>
									<td class="">
										<span class="txt">{{ serviceNameMap[data.serviceType] }}</span>
									</td>
									<td class="">
										<span class="txt">{{ data.membershipName }}</span>
									</td>
									<td class="">
										<span class="txt">{{ orderTypeMap[data.orderType] }}</span>
									</td>
									<td class="date">
										<span class="txt">{{ data.contractDate }}</span>
									</td>
									<td class="date">
										<span class="txt">{{ data.applyDate }}</span>
									</td>
									<td class="item flex">
										<span class="txt">{{ data.summary }}</span>
									</td>
									<td class="charge">
										<span class="txt"> {{ amount(data.amount) }} </span>
									</td>
									<td class="method">
										<span class="txt">{{ orderPayMethodMap[data.payType] || '리셀러' }}</span>
									</td>
									<td class="state">
										<span class="label_state active">{{ orderStatusMap[data.orderStatus].displayName }}</span>
										<button
											v-if="'WAIT' == data.orderStatus"
											v-on:click="orderCancelConfirmPopup($event, idx)"
											class="line mini"
										>
											주문 취소
										</button>
										<!-- // [D] A태그 button으로 변경 -->
									</td>
								</tr>
							</tbody>
						</table>
						<!-- pagenation -->
						<!-- <div class="">  wrap_function -->
						<base-pagination
							v-bind:total="pageEntity.total"
							v-bind:perPage="pageEntity.perPage"
							v-on:page="onPage"
						></base-pagination>
						<!-- 필요시 select 사용 -->
						<!-- <div class="select">
            <select>
                <option selected="selected">10</option>
                <option>30</option>
                <option>50</option>
            </select>
        </div> -->
					</div>
					<!-- </div> -->
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import BasePagination from '@/components/common/BasePagination.vue';
import mixinsAlert from '@/components/common/mixinsAlert';
import { orderTypeMap, serviceNameMap, orderStatusMap, orderPayMethodMap } from '@/utils/constants';
import { currency } from '@/filter/globalFilter';

export default {
	mixins: [mixinsAlert],
	components: {
		BasePagination,
	},
	data() {
		return {
			orderHistories: [],
			pageEntity: {
				total: 0, // 전체 Row수
				perPage: 10,
			},
			orderStatusMap: orderStatusMap,
			serviceNameMap: serviceNameMap,
			orderTypeMap: orderTypeMap,
			orderPayMethodMap: orderPayMethodMap,

			orderCancleConfirm: {
				flag: false,
			},
		};
	},
	created() {
		this.baseList();
	},
	methods: {
		amount(amount) {
			if (amount === null) {
				return '-';
			}
			return (amount | currency) + '원';
		},
		// 이관 코드
		async baseList(pageInfo) {
			const param = {
				membershipId: this.enteredMembershipId,
				page: 0,
				size: 10,
			};

			if (pageInfo) {
				param.page = pageInfo.page;
				param.size = pageInfo.size;
			}

			try {
				const response = await this.$store.dispatch('fetchOrderHistories', param);
				this.orderHistories = response.data.orderHistories;
				this.pageEntity.total = response.data.total;
			} catch (error) {
				console.log(error);
				this.alert({
					contents: '주문/구매 내역이 없습니다.',
					buttons: [{ text: '신규신청', callback: () => this.$router.push('/apply/step1?servicetype=SAAS') }],
				});
			}
		},
		async orderCancel(orderId) {
			console.log(`orderCancle: ${orderId}`);
			await this.$store.dispatch('cancelOrder', orderId);
			this.baseList();
		},
		goDetailPage(idx) {
			const { id } = this.orderHistories[idx];
			this.$router.push(`/user/myStore/OrderDetail/${id}`);
		},
		onPage(obj) {
			const pageInfo = {
				page: obj.currentPage - 1,
				size: obj.currentPerpage,
			};
			this.baseList(pageInfo);
		},
		orderCancelConfirmPopup(e, idx) {
			e.stopPropagation();
			const { id } = this.orderHistories[idx];
			this.alert({
				title: '주문 취소',
				contents: '해당 선택한 주문을 취소하시겠습니까?',
				buttons: [
					{
						text: '주문 취소',
						callback: () => {
							this.orderCancel(id);
						},
					},
					{},
				],
			});
		},
	},
};
</script>

<style scoped></style>
